<template>
  <div class="page">
        <v-nav :styleBool="styleBool"></v-nav>
  
      <div class="img5Box  flex-col ">
        <div class="title">公司简介</div>
        <div class="navbtnBox">
        <div>商屋是一款以企业级社群数字化管理为基础，实现各社群组织之间共建、共享、共商，同时通过社群组织及其成员互动数据沉淀，基于平台大数据推荐算法，为企业提供多元化服务，赋能企业市场化发展的全产业链互联网平台。</div>  
        <div class="mt-16">基于平台共享的上下游产业资讯，跨域的人脉资源整合能力，企业社群组织可多元化赋能会员的企业的业务发展，最大程度提升与会员企业间的粘性，同时通过平台流量加持，放大企业社群组织及成员的资源聚集能力，最终实现全链路数字化商业闭环。</div> 
        </div>
      </div>
      <div class="img5Box  flex-col bgcolor">
        <div class="title">企业愿景</div>
        <div class="navbtnBox">
          我们将持续深耕企业级社群新生态的建设以及社区内企业的服务，专注于私域精细化运营增长，在不断优化用户体验的同时，利用互联网技术进一步助力社群组织的新发展。
        </div>
    </div>

     <!-- <div class="img6Box align-center">
       <img src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6243ec1a5a7e3f0310a1c6bf/6243ec705b22cf00110eab25/16612203613919587202.png" alt="" class="head">
       <div class="userBox">
        <div class="title flex-box">
          高俊鹏<p>创始人/CEO</p>
        </div>
        <div class="message">
          毕业于厦门大学学士，西安交通大学MBA，十五年以上跨城市跨行业市场营销经验，多年京东集团工作经验，曾任京东云西北区市场总监。
        </div>
       </div>
    </div> -->
     <!-- <div class="img6Box lastimg6 align-center">
       <img src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6243ec1a5a7e3f0310a1c6bf/6243ec705b22cf00110eab25/16612203613919587202.png" alt="" class="head">
       <div class="userBox">
        <div class="title flex-box">
          高俊鹏<p>创始人/CEO</p>
        </div>
        <div class="message">
          毕业于厦门大学，获得工学学士学位。十年以上的市场营销经验，多次成功创业经历，创业老兵，连续创业者，曾担任京东西北区销售总监。
        </div>
       </div>
    </div> -->
  </div>
</template>

<script>
import nav from '@/components/nav.vue'
export default {
  name: 'About',
  components:{
    'v-nav':nav
  },
  data(){
    return {
       styleBool:false,
    }
  },
  created(){
  },
   methods: {
     handleScroll() {
      let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
      if(top>0){
        this.styleBool=true
      }else{
         this.styleBool=false
      }
 
    },
   
   },
    mounted() {
    window.addEventListener("scroll", this.handleScroll);
},
   
}
</script>
<style lang="scss" scoped>
// .page{
// margin-top: 72px;
// }
.img5Box{
  background-color: rgba(115, 86, 197, 1);
  padding:0.97rem 6.06rem 1.05rem 3.6rem ;
  .title{
    font-size: 0.44rem;
    color: #fff;
    margin-bottom: 0.24rem;
    font-weight: bold;
  }
  .navbtnBox{
     font-size: 20px;
     line-height: 0.46rem;
    color: #fff;
  }
}
.bgcolor{
  background-color: #FCF7F1;
  .title{
     color: #000000;
  }
  .navbtnBox{
     color: #666666;
  }
}
.img6Box{
  padding: 1.34rem 3.6rem 0 3.6rem;
  .head{
    width: 2.6rem;
    height: 2.6rem;
    margin-right: 0.6rem;
  }
  .userBox{
    .title{
      font-size: 44px;
      color: #000000;
      align-items: baseline;
      font-weight: bold;
      margin-bottom: 0.24rem;
      p{
        font-size:20px;
        margin-left: 0.16rem;
        font-weight: 500;
      }
    }
    .message{
       font-size: 22px;
      color: #666666;
      line-height: 0.36rem;
    }
  }
}
.lastimg6{
  margin-bottom: 1.34rem;
}

</style>

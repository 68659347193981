<template>
  <div
    class="navbox paddingBox"
    :class="styleBool ? 'changenav' : ''"
    v-if="$route.name != 'Home'"
  >
    <div class="navcontent align-center">
    
      <div class="flex1 align-center logotext">
        <div class="navcontent align-center">
          <router-link to="/">
        <img src="@/assets/logo.png" alt="" class="logoimg"
      /></router-link>

        <div class="flex1 align-center">
          <ul v-for="(v, i) in navList" :key="i" class="navul align-center">
            <li
              class="navulLi"
              @click="jump(i)"
              :class="activeindex == i ? 'changenavulLi' : ''"
            >
              {{ v }}
            </li>
          </ul>
          <div class="navulLi2" @click="dialogVisible = true">APP下载</div>
        </div>

        <el-dialog
          :visible.sync="dialogVisible"
          :append-to-body="true"
          :before-close="handleClose"
          center
        >
          <div class="imgbox listcode">
            <img src="../assets/qrcode.png" alt="" class="imgloading" />
            <div class="codepng">扫码下载【商屋】官方APP，完成企业注册登录</div>
          </div>
        </el-dialog>
      </div>
      </div>

      <div class="navbtnBox">
        <el-button class="busBtn" type="primary">
            <a href="/g" class="atext">社群登录</a>
          </el-button>
          <el-button  @click="dialogVisible = true" class="color orgbtn"
            >企业登录</el-button>
        <!-- <el-button class="color orgbtn" ><a href="/g" class="atext">组织登录</a> </el-button>

        <el-button type="primary" class="busBtn" @click="dialogVisible = true"
          >企业登录</el-button
        > -->
      </div>
      <!-- <el-dialog
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :before-close="handleClose"
        center
      >
      <div class="imgbox">
        <img src="../assets/qrcode.png" alt="" class="imgloading">
      </div>
        
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    styleBool: Boolean,
  },
  data() {
    return {
      dialogVisible: false,
      activeindex: -1,
      navList: ["产品服务", "数据安全",  "加入商屋"],
    };
  },
  methods: {
    jump(index) {
      this.activeindex = index;
      if(index==2){
        this.$router.push({ name: "Contact" });
      }else{
        this.$router.push({ path: "/" });
      }
      
    },
    handleClose(done) {
      this.dialogVisible=false
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
   ::v-deep .el-dialog{
    width: 320px;
    height: 320px;
  }
  ::v-deep .el-dialog__body{
    height: 67%;
    display: flex;
    align-items: center;
  }
    .navcontent {
      width: 100%;
    min-width: 7.5rem;
    max-width: 14.3rem;
    height: 72px;
    display: flex;
    justify-content: space-between;
    .logoimg {
      width: 51px;
      height: 44px;
      margin-right: 0.58rem;
    }
    .navulLi2{
      font-size: 16px;
        margin-right: 0.4rem;
        cursor: pointer;
    }
    .navulLi2:hover{
      color: #7356c5;
    }
    .navul {
      .navulLi {
        font-size: 16px;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .changenavulLi {
        color: #7356c5;
        font-weight: bold;
      }

      .navulLi:hover {
        color: #7356c5;
      }
    }

    .navbtnBox {
      .el-button {
        border-radius: 0.04rem;
        width: 96px;
        height: 38px;
        padding: 0;
        font-size: 16px;
        border-color: #7356c5;
      }

      .orgbtn {
        background-color: transparent;
        font-weight: 400;
      }

      .orgbtn:hover {
        border: 2px solid #7356c5;
        font-weight: 500;
      }
    }
  }
  .atext {
    text-decoration: none;
    font-weight: 400;
    color: #fff;
  }
  .atext:hover {
    text-decoration: none;
    font-weight: 500;
    color: #fff;
  }
.changenav {
  background: #fff !important;
  z-index: 100;
}
.navbox {
  min-width: 1200px;
  position:sticky ;
  top: 0;
  left: 0;
  z-index: 999;
  height: 72px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: transparent;
//   .navcontent {
//     width: 100%;

//     .logotext {
//       font-size: 18px;
//       // font-weight: bold;
//       color: #000000;
//     }

//     .logoimg {
//       width: 51px;
//       height: 44px;
//       margin-right: 0.16rem;
//     }

//     .navbtnBox {


     
//     }
//   }
}
.el-button {
        border-radius: 0.04rem;
        width: 96px;
        height: 38px;
        padding: 0;
        font-size: 16px;
        border-color: #7356c5;
      }
.orgbtn {
        background: transparent;
        // background-color: #FCF7F1;
        font-weight: 400;
      }

      .orgbtn:hover {
        border: 2px solid #7356c5;
        // background-color:#fff;
        font-weight: 500;
      }
      
      .busBtn {
        font-weight: 500;
      }
      .busBtn:hover {
        background-color: #5c459e;
        font-weight: 500;
      }
      .imgbox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .imgloading{
        width: 145px;
        height: 130px;
      }
      .codepng{
        margin-top: 40px;
        font-size: 12px;
      }
      .listcode{
        flex-direction: column !important;
      }
</style>
